import { toaster, Message } from "rsuite";
import { Check_login_ticket, getLogin, getToken, getView } from "../../ressources/functions/helper";
import { config as getConfig } from "../../ressources/functions/Config.js";
import { submitSolrQuery } from "../../ressources/functions/Query";
import { checkAcl } from "../../adminressources/ACLHelper.js";
import { MultiCampus } from "../../ressources/functions/Login.js";

export const MASTER_TOKEN = "ZoQhXcMolvvjYKjEvZ3GO54bbi1EvU2Hh5qRt95anX6eW1959+clS4hgDESf/vTK6w=="

// Variable to limit the collection desc size
export const collection_desc_limit = 1000

// Variable to limit the term desc size
export const portal_term_desc_limit = 15000

export const filterTypes = [
    {
        "label": "Name",
        "value": "collection_title"
    },
    {
        "label": "Files",
        "value": "collection_files"
    },
    {
        "label": "Created Date",
        "value": "collection_date"
    },
]

export const filterOrder = [
    {
        "label": "Ascending",
        "value": "ascending"
    },
    {
        "label": "Descending",
        "value": "descending"
    }
]

export const filterPublish = [
    {
        "label": "Publishing Status",
        "value": "all"
    },
    {
        "label": "Published",
        "value": "published"
    },
    {
        "label": "UnPublished",
        "value": "unpublished"
    }
]

export const sortCollections = (collections, sortOrder, type) => {

    if (sortOrder === "ascending") {
        if (type === "collection_title") {
            collections = collections.sort((a, b) => {
                var x = a[type].toLowerCase();
                var y = b[type].toLowerCase();

                return ((x > y) ? -1 : ((x < y) ? 1 : 0));
            })
        } else {
            collections = collections.sort((a, b) => {
                var x = a[type];
                var y = b[type];

                return ((x > y) ? -1 : ((x < y) ? 1 : 0));
            })
        }

    } else {
        if (type === "collection_title") {
            collections = collections.sort((a, b) => {
                var x = a[type].toLowerCase();
                var y = b[type].toLowerCase();

                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            })
        } else {
            collections = collections.sort((a, b) => {
                var x = a[type];
                var y = b[type];

                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            })
        }

    }

    return collections;
}

// Filter the collections
export const filterCollections = (collections, FilterType) => {

    if (FilterType == null) {
        return collections;
    }
    let collection_filtered = collections.filter(e => e.collection_type === FilterType.toLowerCase())
    return collection_filtered;
}

// Initial state of Advance Query
export const defaultAdvanced = {
    and: true,
    field: "",
    field_selected: false,
    op: "~=",
    value: ""
}

// Gets the src of the image
export const getSrc = (props, type) => {

    let solrVersion = props.cm_modified || "0"


    let preview_kinds = props.preview_kind;
    // let preview_ref = props.preview_ref;

    let kind = undefined;
    // let ref = undefined; //ref is just to update if there is a change to the rendition

    let token = getToken();

    if (type === "original") {
        if (preview_kinds !== undefined && preview_kinds !== null) {
            if (!preview_kinds.includes("pdfthumb") && !preview_kinds.includes("xthumb") && !preview_kinds.includes("heifbbox") && !preview_kinds.includes("rawbbox")) {
                kind = type;
            }
        } else {
            kind = type;
        }
    }

    if (preview_kinds !== undefined && preview_kinds !== null) {
        if (kind !== "original") {
            let idx = preview_kinds.findIndex(kind => kind === "doclib" || kind.indexOf("thumb") >= 0);
            if (type === "original") {
                idx = preview_kinds.findIndex(kind => kind === "doclib" || kind.indexOf("bbox") >= 0);
            }

            if (idx !== -1) {
                kind = preview_kinds[idx];
            }
        }
    }

    if (type !== "original" && type !== "betterthumb" && type !== undefined && type !== null) {
        kind = type
    }

    let token_encode = encodeURIComponent(token);
    let src = undefined;

    if (kind === undefined || kind === null) {
        kind = "original";
    }

    src = `../api/v1/repo/s/semabench/preview?id=${props.id}&semabench-token=${token_encode}&kind=${kind}&version=${solrVersion}`;

    return src;

}

// Method for getting collections
export const getCollections = async () => {
    let token = getToken();

    return await fetch("../api/v1/collections", {
        method: 'GET',
        headers: { "semabench-token": token }
    }).then(res => {
        //redirect the users to the login pages if the login token is invalid
        if (res.status === 401) {
            Check_login_ticket()
        }
        return res.json()
    })
        .then((result) => {

            let config = getConfig
            let login = getLogin()

            if (!checkAcl(login.roles, config.acls, "ADMIN") && checkMultiCampus()) {
                //as admin can see all functions, it is only applied not admin users if the multi campus is setup
                if (config != null) {
                    if (config.multicampus != null) {
                        if (config.multicampus.length > 0) {

                            let campus = MultiCampus;
                            let sorted = [];

                            //check the campus restriction for the campus user to have the collections that have the campus restriction
                            result.forEach(collection => {
                                if (collection.collection_restrictions !== "{}" && collection.collection_restrictions !== undefined && collection.collection_restrictions !== null) {
                                    let restriction = JSON.parse(collection.collection_restrictions)
                                    if (restriction.campus !== undefined && restriction.campus !== null) {
                                        if (restriction.campus.length > 0 && restriction.campus.filter(restricted_campus => campus.includes(restricted_campus)).length > 0) {
                                            sorted.push(collection);
                                        }
                                    }
                                }
                            });

                            // sort the collection by title
                            let collections = sorted.sort((a, b) => {
                                var x = a.collection_title.toLowerCase(); var y = b.collection_title.toLowerCase();
                                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
                            })

                            return collections;

                        }
                    }
                }

                return
            } else {
                // if no multi campus setup
                let collections = result.sort((a, b) => {
                    var x = a.collection_title.toLowerCase(); var y = b.collection_title.toLowerCase();
                    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
                })

                return collections;
            }

        })
}

// Method for getting collections
export const getCollectionsByType = async (type) => {
    let token = getToken();

    return await fetch("../api/v1/collections", {
        method: 'GET',
        headers: { "semabench-token": token }
    }).then(res => res.json())
        .then((result) => {
            let sorted = [];
            result.forEach(collection => {
                if (collection.collection_type.toLowerCase() === type.toLowerCase()) {
                    sorted.push(collection);
                }
            });


            let collections = sorted.sort((a, b) => {
                var x = a.collection_title.toLowerCase(); var y = b.collection_title.toLowerCase();
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            })

            return collections;
        })
}

// Method for getting collections
export const getCollectionByID = async (collection_id) => {
    let token = getToken();

    return await fetch("../api/v1/collections", {
        method: 'GET',
        headers: { "semabench-token": token }
    }).then(res => res.json())
        .then((result) => {
            let collection = undefined
            if (result !== undefined && result !== null) {
                result.forEach(col => {
                    if (col.ucid === +collection_id) {
                        collection = col
                    }
                })
            }
            return collection;
        })
}


// Method for adding collection
export const addCollection = async (collection) => {
    let token = getToken();

    return await fetch("../api/v1/collection", {
        method: "POST",
        headers: { "semabench-token": token, "Content-Type": "application/json" },
        body: JSON.stringify(collection)
    }).then(res => {
        if (res.ok) {
            toaster.clear();
            toaster.push(<Message type="success" showIcon closable>Successfully Added Collection</Message>);
            return true
        } else {
            toaster.clear();
            toaster.push(<Message type="error" showIcon closable>Unable to Add Collection</Message>);
            return true
        }

    })
        .catch(error => {
            toaster.clear();

            toaster.push(<Message type="error" showIcon closable>{error}</Message>);
            return false
        })
}

// Method for updating collection data when changed
export const updateCollection = async (collection) => {
    let token = getToken();

    return await fetch("../api/v1/collection", {
        method: "PUT",
        headers: { "semabench-token": token, "Content-Type": "application/json" },
        body: JSON.stringify(collection)
    }).then(res => res.text())
        .then(result => {
            return true
        })
        .catch(error => {
            toaster.push(<Message type="error" showIcon closable>{error}</Message>);
            return false
        })
}

// Method for removing Collection
export const removeCollection = async (collection_id) => {
    let token = getToken();

    return await fetch("../../api/v1/collection?ucid=" + collection_id, {
        method: "DELETE",
        headers: { "semabench-token": token }
    }).then(res => res.json())
        .then(async result => {
            return true;
        }).catch(error => {
            toaster.push(<Message type="error" showIcon closable>Error getting files from collection</Message>);
            return false;
        })

}

// Method for returning the nodes that belong within that collection
export const getCollectionNodes = async (collection_id) => {
    let token = getToken();
    let view = getView()

    return await fetch("../api/v1/collection/node?ucid=" + collection_id, {
        method: "GET",
        headers: { "semabench-token": token }
    }).then(res => res.json())
        .then(async result => {

            let new_view = view;
            let filters = [];

            // Addes filters in if album nodes greater than 0
            if (result.length > 0) {
                filters.push({ field_name: "id", value: result, inverse: false })

                // creates the filter
                new_view.filters = filters;
                view = new_view;

                // Saves as current filter to config
                localStorage.setItem("V", JSON.stringify(view));

                return await submitSolrQuery("collection", 200).then(query => {
                    return query;
                }
                );
            } else {
                return [];
            }


        })
        .catch(error => {
            toaster.push(<Message  >Error getting files from collection</Message>);
        })
}

// Method for Adding nodes into the selected collection
export const addCollectionNodes = async (collection_id, nodes) => {
    let token = getToken();

    let body = {
        ucid: collection_id,
        nodes
    }

    return await fetch("../api/v1/collection/node?ucid=" + collection_id, {
        method: "Post",
        headers: { "semabench-token": token, "Content-Type": "application/json" },
        body: JSON.stringify(body)
    }).then(res => res.json())
        .then(async result => {
            toaster.push(<Message type="success" showIcon closable>{result}</Message>, { duration: 5000 });
        })
        .catch(error => {
            toaster.push(<Message type="error">{"Error Adding to Collection - " + error}</Message>);
        })
}

// Method for removing nodes
export const removeCollectionNodes = async (collection_id, nodes) => {
    let token = getToken();

    let body = {
        ucid: parseInt(collection_id),
        nodes
    }

    return await fetch("../api/v1/collection/node?ucid=" + collection_id, {
        method: "DELETE",
        headers: { "semabench-token": token, "Content-Type": "application/json" },
        body: JSON.stringify(body)
    }).then(res => {
        toaster.push(<Message type="success" showIcon closable>Files removed from collection</Message>);
        return true;
    })
        .catch(error => {
            toaster.push(<Message type="error" showIcon closable>{"Error removing from Collection - " + error}</Message>);
            return false
        })
}

// Download function
export const downloadClick = async (props, type, single) => {

    let token = getToken();

    let nodes = [];

    // Adds all the selected nodes to be downloaded
    if (single !== undefined && single !== null) {
        if (single !== true) {
            if (props.docs !== undefined && props.docs !== null) {
                nodes = props.docs;
                if (!nodes.includes(props.id)) {
                    nodes.push(props.id);
                }
            } else {
                nodes.push(props.id);
            }
        } else {
            nodes.push(props.id);
        }
    } else {
        if (props.docs !== undefined && props.docs !== null) {
            nodes = props.docs;
            if (!nodes.includes(props.id)) {
                nodes.push(props.id);
            }
        } else {
            nodes.push(props.id);
        }
    }

    // Sets the payload to the correct data format
    let payload = { nodes };

    let downloadUrl = "../api/v1/repo/s/semabench/download?semabench-token=" + encodeURIComponent(token);

    // If no type is selected (Rendition) then its default to original
    if (type === undefined || type === null) {
        type = "original"
    }

    // Fetch request to connect to alfresco and get the images based on rendition type selected by the user
    return await fetch(downloadUrl, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: { 'Content-Type': 'application/json', "Semabench-Token": token }
    }).then(resp => resp.json())
        .then(async result => {

            // After getting a set (alfresco creates a temp set of the selected images)
            let alfresco_url = downloadUrl + "&set=" + result.set + "&kind=" + encodeURIComponent(type);
            window.location = "../" + alfresco_url;
            return true
        })
}


//method of loading verification data
export async function getVerifications(status) {

    let url = "/admin/api/v1/new_portal/verifications/" + status;

    let result = await fetch(url, {
        method: "GET",
        headers: { "semabench-token": MASTER_TOKEN }
    })

    return await result.json().then(async resp => {
        return resp;
    }).catch(error => {
        // returns the error message back to the form view to be displayed
        return undefined
    })

}

//method of loading verification data
export async function updateVerifications(status, update, item) {
    //status = current status, action = button (approve/reject/revert), item = item
    const login_info = getLogin();

    let username = login_info.username;
    if (username == null) {
        username = login_info.email
    }

    let url = "/admin/api/v1/new_portal/update/verifications/" + status + "," + update + "," + item.item_id + "," + item.user_id + "," + item.time + "," + username;

    let result = await fetch(url, {
        method: "POST",
        headers: { "semabench-token": MASTER_TOKEN }
    })

    return await result.json().then(async resp => {
        return resp;
    }).catch(error => {
        // returns the error message back to the form view to be displayed
        return undefined
    })

}

// load all facets required
export const loadDocs = async (id) => {

    let headers = { 'Content-Type': 'application/json', "Semabench-Token": MASTER_TOKEN, "Cache-Control": "max-age=0" };

    let query = { query: 'id:' + id }

    return await fetch('../api/v1/repo/s/semabench/query?q=*:*&rows=5000', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(query)
    }).then(resp => resp.json())
        .then(response => {
            return response
        })
}

//update IDcheck information on the image
export const saveImage = async (node, bbox, uuid, update) => {

    let payload = new FormData();
    let new_nodes = []
    if (node !== undefined && node !== null) {
        new_nodes.push({ "id": node, "storeRef": { "protocol": "workspace", "identifier": "SpacesStore" } })
    }

    payload.append("nodes", JSON.stringify(new_nodes))
    payload.append("sb_adminStatus", "Unverified")
    if (update === undefined || update === null) {
        payload.append("sb_boundingBox", JSON.stringify(bbox.map(row => JSON.stringify(row))));
        if (uuid !== undefined && uuid !== null) {
            payload.append("sb_isNotUUID", JSON.stringify(uuid))
        }

    }

    // if there is a uuid send through, will add to the sb_isNotUUID field. So facenet will skip.

    let token = getToken();

    await fetch(`../../api/v1/repo/s/semabench/items`, {
        method: "POST",
        body: payload,
        headers: { "Semabench-Token": token }
    }).then(resp => resp.json())
        .then(async response => {
            return await response
        })
}

// convert sb_boundingBox info to readable data
export const convertBBOX = async (bboxes) => {
    let bbox = [];
    let uuids = [];

    // loops through the image and creates the bbox object from string.
    // Adds the uuids to a list to then get the profile info fomr the UUIDs
    // depending on bbox settings, will show "Is this {{profile}}"
    if (bboxes !== undefined && bboxes !== null) {
        for (var i = 0; i < bboxes.length; i++) {
            let bboxVal = {};

            try {
                bboxVal = JSON.parse(bboxes[i]);
            } catch (e) {
                bboxVal = bboxes[i];
            }

            if (bboxVal.uuid !== undefined && bboxVal.uuid !== null) {
                uuids.push(bboxVal.uuid);
            }
            bbox.push(bboxVal);
        }
    }
    let new_bbox = []

    bbox.forEach(box => {
        new_bbox.push(box);
    })

    return new_bbox
}

// Loads the profile Data
export const loadProfile = async (nodes, reload) => {

    if (nodes.includes(undefined)) {
        let index = nodes.indexOf(undefined);
        nodes.splice(index, 1);
    }

    if (nodes.includes(null)) {
        let index = nodes.indexOf(null);
        nodes.splice(index, 1);
    }

    let newNodes = [];
    nodes.forEach(node => {
        if (node != null && node !== undefined) {
            newNodes.push(node);
        }
    })

    let payload = {
        nodes: newNodes
    }

    let token = getToken();

    let headers = { 'Content-Type': 'application/json', "Semabench-Token": token, "Cache-Control": "max-age=0" };
    return fetch("../api/v1/repo/s/semabench/metadata", {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload)
    }).then(resp => resp.json())
        .then(async response => {
            let profiles = [];
            if (response.length !== 0) {
                response.forEach(node => {
                    node.selected = false;
                    profiles = { ...profiles, [node.id]: node }
                })
            }
            return profiles
        })
}

// Calls the api to get the UAIDs that the node belongs in
export async function getCollectionsbyNode(node) {
    let token = getToken();
    let headers = { 'Content-Type': 'application/json', "Semabench-Token": token, "Cache-Control": "max-age=0" };

    return fetch("../api/v1/collections/node?node=" + node, {
        method: 'GET',
        headers: headers
    })
        .then(res => res.json())
        .then((result) => {
            return result
        }).catch((error) => {
            console.error('Error:', error);
            return undefined;
        });

}

// Calls the api to get the UAIDs that the node belongs in
export async function getInCollectionsbyNode(node) {
    let token = getToken();
    let headers = { 'Content-Type': 'application/json', "Semabench-Token": token, "Cache-Control": "max-age=0" };

    return fetch("../api/v1/incollections?node=" + node, {
        method: 'GET',
        headers: headers
    })
        .then(res => res.json())
        .then((result) => {
            return result
        }).catch((error) => {
            console.error('Error:', error);
            return undefined;
        });

}

//check multi campus setup
export const checkMultiCampus = () => {
    let config = getConfig
    let login = getLogin()

    let isAdmin = checkAcl(login.roles, config.acls, "ADMIN");


    let isMultiCampus = false
    if (config != null) {
        if (config.multicampus != null) {
            if (config.multicampus.length > 0) {
                isMultiCampus = true;
            }

            if (!isAdmin) {
                let campus = MultiCampus;
                if (campus === null) {
                    isMultiCampus = false;
                } else {
                    if (campus.length === 0) {
                        isMultiCampus = false;
                    }
                }
            }
        }
    }

    return isMultiCampus
}

//multi campus admin checker
export const AdminChecker = () => {
    let config = getConfig
    let login = getLogin()

    let isAdmin = checkAcl(login.roles, config.acls, "ADMIN");

    return isAdmin
}

//Get the list of campuses allocated to the user
export const getMultiCampuslist = () => {
    let config = getConfig
    let login = getLogin()

    let isAdmin = checkAcl(login.roles, config.acls, "ADMIN");

    let campus = []
    if (config != null) {
        if (config.multicampus !== null) {
            if (config.multicampus.length > 0) {
                if (isAdmin) {
                    config.multicampus.map((items) => { return campus = [...campus, ...items.campus] })
                    campus = [...new Set(campus)];
                } else {
                    campus = MultiCampus;
                    if (campus == null) {
                        campus = [];
                    }
                }
            }
        }
    }

    return campus
}

//API call to load students
export const loadCampusProfiles = async (value) => {
    let token = getToken();
    let headers = { 'Content-Type': 'application/json', "Semabench-Token": token, "Cache-Control": "max-age=0" };
    let campus = getMultiCampuslist()
    let query = { query: "sb_adminStatus:Profile AND sb_studentCampus:(\"" + campus.join("\", \"") + "\")" }

    //apply the campus filter
    if (value !== undefined && value !== null && value !== "all" && value !== "") {
        query = { query: "sb_adminStatus:Profile AND sb_studentCampus:(\"" + value + "\")" }
    }
    return await fetch('../api/v1/repo/s/semabench/query?q=sb_adminStatus:"Profile"&rows=5000', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(query)
    }).then(resp => resp.json())
        .then(result => {
            return result;
        })
}

//Get the list of campuses allocated to the user
export const getCampusFilter = () => {
    let config = getConfig
    let login = getLogin()

    let isAdmin = checkAcl(login.roles, config.acls, "ADMIN");

    let campus = []
    if (config != null) {
        if (config.multicampus != null) {
            if (config.multicampus.length > 0) {
                if (isAdmin) {
                    config.multicampus.map((items) => { return campus = [...campus, ...items.campus] })
                    campus = [...new Set(campus)];
                } else {
                    campus = MultiCampus;
                    if (campus == null) {
                        campus = [];
                    }
                }
            }
        }
    }

    let campus_filter = [{
        "label": "Campus",
        "value": "all"
    }];
    campus.forEach(ca => {
        campus_filter.push({
            "label": ca,
            "value": ca
        })
    });

    return campus_filter
}

//Get the list of campuses allocated to the user
export const getCampuslist = () => {
    let config = getConfig

    let campus = []
    if (config != null) {
        if (config.multicampus != null) {
            if (config.multicampus.length > 0) {
                config.multicampus.map((items) => { return campus = [...campus, ...items.campus] })
                campus = [...new Set(campus)];
            }
        }
    }

    let campus_filter = []

    campus.forEach(ca => {
        campus_filter.push({
            campus: ca,
            banner: "",
            enable_banner: false,
            banner_url: ""
        })
    });

    return campus_filter
}
