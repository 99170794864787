import Handlebars from 'handlebars';
import { getPortalSubscription } from "../../views/portalplus/functions/PPSubscription"
import { getView } from './helper';

export var config = "";

// Inistail state of the config.
export const initialState = {
    acls: [],
    acl_fields: [],
    view: {
        sort: { field: "cm_modified", direction: "desc" },
        query: "",
        facets: ["path_starts_with"],
        columns: ["cm_name", "cm_title", "cm_modified"],
        filters: [],
        results_view: "grid",
        filter_rules: [],
    },
    summary_views: [],
    result_views: [],
    colour_rules: [],
    social_media: {
        facebook_filters: [],
        instagram_filters: []
    },
    media: { allow_social: false },
    title: "SchoolBench®",
    default_filter: "",
    filter_rules: {},
    path_strip: "/Company Home/",
    drop_folder: "/Company Home/Semabench",
    loaded: false,
    failed: false,
    suggested_searches: [],
    pipeline_names: {},
    has_watermark: false,
    enable_quick_parent: false,
    enable_community: false,
    enable_timeout: "false",
    action_timeout: "10",
    popup_timeout: "30"
};

// initial state of the info and default fields
const infoInitialState = {
    fields: {
        "path_starts_with": {
            title: "Path Starts With",
            type: "path",
            is_mandatory: false,
            is_multivalued: false
        },
        "type": {
            title: "Type",
            type: "text",
            is_mandatory: false,
            is_multivalued: false
        },
        "mime_type": {
            title: "Mime type",
            type: "text",
            is_mandatory: false,
            is_multivalued: false
        },
        "size": {
            title: "File size",
            type: "long",
            is_mandatory: false,
            is_multivalued: false
        },
        "preview_kind": {
            title: "Preview Kind",
            type: "text",
            is_mandatory: false,
            is_multivalued: true
        },
        "id": {
            title: "Node ID",
            type: "text",
            is_mandatory: false,
            is_multivalued: false
        }
    },
    // for provide the corresponding labels for fields name coming back from solr or Alfresco
    lookup: { "preview_kind": "Preview Kind", "path": "Path", "path_starts_with": "Path Starts With", "type": "Type", "size": "File size", "mime_type": "Mime type", "aspects": "Aspects", "site": "Site", "id": "Node ID", "[NOW-1DAY TO NOW]": "Last 24hrs", "[NOW-7DAYS TO NOW]": "Last 7 days", "[NOW-1MONTH TO NOW]": "Last 31 days", "[NOW-1YEAR TO NOW]": "Last 365 days", "[* TO NOW-1YEAR]": "More than a year ago" },
    error: {}
};


export var Field_Info = infoInitialState;

/**
 * Take a config and augment with any default values or adjustments that need to be made before
 * loading in the config
 */
const populateDefaultValues = (config) => {

    if (config.view.filter_rules === undefined || config.view.filter_rules === null) {
        config.view.filter_rules = populateFilterRules(config);
    }

    // Populate any missing keys from initialState (eg. user has upgraded and is missing a view key)
    Object.keys(initialState.view).forEach(key => {
        if (config.view[key] === undefined || config.view[key] === null) {
            config.view[key] = initialState[key];
        }
    });

    return config;
}

// populates the default filter rules if undefined
const populateFilterRules = (config) => {
    if (config.filter_rules !== undefined && config.filter_rules !== null) {
        return Object.keys(config.filter_rules);
    }
    return [];
}

// Loads the config from the server
export function loadConfig() {
    fetch("../api/v1/config", {
        mode: 'no-cors',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }
    })
        .then((response) => response.json())
        .then(async (resp) => {
            config = resp.config;
            config.view.filter_rules = populateFilterRules(config);
            config = populateDefaultValues(config);
            let acls = [];
            let acl_fields = [];
            if (resp.acl !== undefined && resp.acl !== null) {
                // Goes through and sorts out all acls from config
                acls = resp.acl.map(acl => {

                    acls.roles = acl.roles.map(role => {
                        let transformedRole = role;

                        if (typeof role === 'string') {
                            let re = /{{[{]?(.*?)[}]?}}/g;
                            let match = null;
                            let is_template = false;

                            while ((match = re.exec(role))) {
                                is_template = true;
                                acl_fields.push(match[1]);
                            }

                            if (is_template) {
                                transformedRole = Handlebars.compile(role);
                            }
                        }

                        return transformedRole;
                    })



                    if (acl.params !== undefined && acl.params != null) {
                        if (acl.params.matched_values !== undefined && acl.params.matched_values !== null) {
                            Object.keys(acl.params.matched_values).forEach(val => acl_fields.push(val));
                        }
                    }

                    return acl;
                    // return role
                })

                config.acls = acls;
                config.acl_fields = acl_fields;

                await getPortalSubscription().then(result => {
                    if (result === 1) {
                        config.portal_valied = true;
                    } else if (result === 0) {
                        config.portal_valied = false;
                    }
                })
            }
            // Saves the config to local browser data that other parts will use
            let view = JSON.parse(localStorage.getItem("V"));
            if (view === null || view === undefined) {
                localStorage.setItem("V", JSON.stringify(config.view));
            }

            loadInfo(resp.fields);

        })
}


// Loads the config from the server
export function updateConfig(new_config) {
    config = new_config
}

function loadInfo(field_info) {
    if (field_info !== undefined && field_info !== null) {
        let lookup = Field_Info.lookup;
        let fields = field_info;

        if (fields["cm_content"]) {
            fields["cm_content"].is_multivalued = true;
        }

        Object.keys(fields).forEach((key) => {
            if (fields[key].title) {
                lookup[key] = fields[key].title;
            }
        });

        Field_Info.fields = {
            ...Field_Info.fields,
            ...fields
        }
        Field_Info.lookup = lookup;
    }

}

// Method called to get the form from the local storage
export const getForm = () => {
    let form = {
        "rulesets": [
            {
                "name": "default_node",
                "conditions": {
                    "required_fields": ["cm_content"],
                    "create_mode": true,
                    "initial_only": true
                },
                "templates": {
                    "path": "/Company Home/Schoolbench/{{substring id 0 2}}/{{substring id 2 4}}/",
                    "sb_adminStatus": "Unverified",
                    "type": "cm_content",
                    "cm_name": "{{{filename}}}"
                }
            },
            {
                "name": "profile",
                "conditions": {
                    "required_fields": ["sb_profileName"],
                    "create_mode": true,
                },
                "templates": {
                    "sb_adminStatus": "Profile"
                }
            }
        ]
    }
    return form;
}

export const checkFacetList = (facetToCheck) => {
    const localStorageData = localStorage.getItem('V');
    let facet = [];
    if (localStorageData) {
        facet = localStorageData.facets || [];
    }
    return facet.includes(facetToCheck);
}

export const DefaultFilterList = [
    { label: "Class", value: "sb_class" },
    { label: "Year", value: "sb_year" },
    { label: "Event", value: "sb_event" },
    { label: "Tags", value: "sb_tags" },
    { label: "Student", value: "sb_student" },
    { label: "Staff", value: "sb_staff" },
    { label: "Past Student", value: "sb_pastStudent" },
    { label: "Year Level", value: "sb_studentYearLevel" },
    { label: "Consent", value: "sb_parentPermissions" },
    { label: "Allowed To Publish", value: "sb_allowedTo" },
    { label: "File Format", value: "mime_type" },
    { label: "Taken Date", value: "exif_dateTimeOriginal" },
    { label: "Boarder", value: "sb_studentBoarder" },
    { label: "Campus", value: "sb_studentCampus" },
    { label: "Cohort", value: "sb_studentCohort" },
    { label: "House", value: "sb_studentHouse" },
    { label: "SubSchool", value: "sb_studentSubSchool" },
    { label: "Visitor", value: "sb_visitor" },
    { label: "Published History", value: "sb_publishedTo" },
    { label: "Rendition", value: "preview_kind" },
    { label: "Other Names", value: "sb_otherNames" },
    ...(checkFacetList("sb_uploaderCampus") ? [{ label: 'Uploader Campus', value: 'sb_uploaderCampus' }] : []),
    { label: 'Week', value: 'sb_week' },
    { label: 'Term', value: 'sb_term' },
    { label: 'Upload Date', value: 'cm_created' }
];

export const FilterNameChanges = {
    'sb_studentBoarder': 'Boarder',
    'sb_studentCampus': 'Campus',
    'sb_studentCohort': 'Cohort',
    'sb_studentHouse': 'House',
    'sb_studentSubSchool': 'SubSchool',
    'sb_publishedTo': 'Published History',
    'sb_studentYearLevel': 'Year Level',
    'sb_parentPermissions': 'Consent',
    'preview_kind': 'Rendition',
    'mime_type': 'File Format',
    'sb_uploaderCampus': 'Uploader Campus'
};

export const ArchivedFilters = [
    { label: 'Boarder', value: 'sb_studentBoarder' },
    { label: 'Campus', value: 'sb_studentCampus' },
    { label: 'Cohort', value: 'sb_studentCohort' },
    { label: 'House', value: 'sb_studentHouse' },
    { label: 'SubSchool', value: 'sb_studentSubSchool' },
    { label: 'Visitor', value: 'sb_visitor' },
    { label: 'Published History', value: 'sb_publishedTo' },
    { label: 'Rendition', value: 'preview_kind' },
    { label: 'Other Names', value: 'sb_otherNames' },
    ...(checkFacetList("sb_uploaderCampus") ? [{ label: 'Uploader Campus', value: 'sb_uploaderCampus' }] : []),
    { label: 'Week', value: 'sb_week' },
    { label: 'Term', value: 'sb_term' },
    { label: 'Upload Date', value: 'cm_created' }
];

export const extendColumnsWithLabel = () => {
    const localStorageData = getView();
    let columns = localStorageData?.columns || [];

    const unchangedColumnFields = columns.map(column => ({ label: Field_Info["lookup"][column], value: column }));

    const transformedColumns = unchangedColumnFields.map(option => {
        if (FilterNameChanges[option.value]) {
            return {
                ...option,
                label: FilterNameChanges[option.value]
            };
        }
        return option;
    });
    return transformedColumns;
};

export const saveViewType = (view_type) => {
    const localStorageData = getView();

    let parsedData;
    if (localStorageData) {
        parsedData = localStorageData;
    } else {
        parsedData = {};
    }

    parsedData.view_type = view_type;

    const updatedLocalStorageDataString = JSON.stringify(parsedData);

    localStorage.setItem('V', updatedLocalStorageDataString);
};

export const getViewType = () => {
    const localStorageData = getView();
    if (localStorageData) {
        return localStorageData.view_type || 'default';
    }
    return 'default';
};

export const saveFilterValues = () => {
    const localStorageData = getView();

    let parsedData;
    if (localStorageData) {
        parsedData = localStorageData;
    } else {
        parsedData = {};
    }

    const currentFilters = parsedData.filters;

    parsedData.persisted_filters = currentFilters;

    const updatedLocalStorageDataString = JSON.stringify(parsedData);

    localStorage.setItem('V', updatedLocalStorageDataString);
};

export const loadFilterValues = () => {
    const localStorageData = getView();
    if (localStorageData) {
        return localStorageData.persisted_filters || [];
    }
    return [];
};

export const getPersistedFilterValue = (filterName) => {
    const localStorageData = getView();
    if (localStorageData) {
        const persistedFilters = localStorageData.persisted_filters || [];

        // Find the specific filter based on the field_name
        const filter = persistedFilters.find(
            item => item.field_name === filterName
        );

        if (filter) {
            return filter.value;
        }
    }
    return null;
};

export const checkAvailableConsent = (nodes, consent_to_check) => {
    if (!nodes) {
        return false;
    }

    const available_consents = nodes.find(node => node.field_name === 'sb_parentPermissions');

    if (available_consents && Array.isArray(available_consents.childNodes)) {
        return available_consents.childNodes.some(childNode => childNode.label === consent_to_check);
    }

    return false;
}
